import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo1 from "../images/logo.png";
import logo2 from "../images/SNU.png";
import {
  EmailIcon,
  FaceBookIcon,
  InstagramIcon,
  LinkedInIcon,
  LocationIcon,
  PhoneIcon,
} from "./icon";

const FooterContainer = styled.footer`
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #738c99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 114px;

  @media screen and (max-width: 1180px) {
    font-size: 1rem;
    align-items: flex-start;
    padding: 40px 16px;
    gap: 24px;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
    align-items: flex-start;
  }
`;

const CompanyHeadContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 118px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;
  }
`;

const CompanyContainer = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 35px;
  }
`;

const Company = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const CompanyLogo = styled.img`
  height: 50px;
  width: auto;
  object-fit: contain;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    height: 60px;
  }
`;

const CompanyInfoIcon = styled.div``;

const CompanyInfo = styled.div`
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 18px;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    gap: 16px;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  margin-right: auto;
  gap: 1rem;
`;

const SocialMediaIcon = styled.a`
  color: #969696;
  margin: 0 0.5rem;
  font-size: 1.5rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: transparent;
`;

const SiteMap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  justify-content: space-between;

  @media screen and (max-width: 1180px) {
    gap: 7rem;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: 0rem;
  }
`;

const SiteMapSection = styled.div`
  width: auto;
  font-size: 14px;
  font-family: "Sora";
  margin: 0;

  @media screen and (max-width: 1180px) {
    width: auto;
    text-align: left;
    margin: 0 1rem;
  }
  @media screen and (max-width: 768px) {
    width: auto;
    margin: 0;
  }
`;

const LastSiteMapSection = styled.div`
  width: auto;
  font-size: 14px;
  font-family: "Sora";
  margin: 0;

  @media screen and (max-width: 1180px) {
    width: auto;
    text-align: left;
    margin: 0 1rem;
  }
  @media screen and (max-width: 768px) {
    width: auto;
    margin: 32px 0px 0px 0px;
  }
`;

const SiteMapTitle = styled.h3`
  font-size: 18px;
  font-family: "Syne";
  font-weight: 700;
  margin-bottom: 24px;
  color: #fff;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const SiteMapLink = styled(Link)`
  display: block;
  color: #fff;
  text-decoration: none;
  margin-bottom: 12px;

  &:hover {
    color: #fff;
  }
`;

const Copyright = styled.p`
  width: fit-content;
  color: #fff;
  font-size: 12px;
  margin-right: auto;
  @media screen and (max-width: 1180px) {
    font-size: 0.7rem;
  }
`;

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 48px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
  }

  @media (max-width: 768px) {
    margin-top: 24px;
  }
`;

const FooterTxt = styled.span`
  width: fit-content;
  font-size: 12px;
  font-family: "Sora";
  color: #fff;
`;

const FooterTxtWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media screen and (min-width: 768px) {
    gap: 40px;
  }
  @media screen and (max-width: 768px) {
    gap: 24px;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <CompanyHeadContainer>
        <CompanyContainer>
          <Company>
            <CompanyLogo src={logo1} alt="Company 1 Logo" />
            <CompanyInfo>
              <CompanyInfoIcon>
                <LocationIcon />
              </CompanyInfoIcon>
              No.1106, 11th Floor, Pearl Condo, Block C, Bahan Township, Yangon
            </CompanyInfo>
            <CompanyInfo>
              <CompanyInfoIcon>
                <EmailIcon />
              </CompanyInfoIcon>
              support@figma.com
            </CompanyInfo>
            <CompanyInfo>
              <CompanyInfoIcon>
                <PhoneIcon />
              </CompanyInfoIcon>
              +1 800 854-36-80
            </CompanyInfo>
          </Company>
          <Company>
            <CompanyLogo src={logo2} alt="Company 2 Logo" />
            <CompanyInfo>
              <CompanyInfoIcon>
                <LocationIcon />
              </CompanyInfoIcon>
              Ground Floor, Pearl Condo, Block C, Bahan Township, Yangon
            </CompanyInfo>
          </Company>
          <SocialMedia>
            <SocialMediaIcon
              href="https://www.facebook.com/SafenetEducation"
              target="_blank"
              rel="noopener noreferrer"
              className="facebook"
            >
              <FaceBookIcon />
            </SocialMediaIcon>
            {/* <SocialMediaIcon
  href="https://t.me/safeneteducation?fbclid=IwAR2ZsnVXrtfByDGJ8Ex8XS0HeLwtRd0p9OYRFgN1aSjJFG0HfYxGylkqql8"
  target="_blank"
  rel="noopener noreferrer"
  className="telegram"
>
  <FaTelegramPlane />
</SocialMediaIcon> */}
            <SocialMediaIcon
              href="https://www.instagram.com/safenetedu_2023/"
              target="_blank"
              rel="noopener noreferrer"
              className="instagram"
            >
              <InstagramIcon />
            </SocialMediaIcon>
            <SocialMediaIcon
              href="https://www.linkedin.com/company/safenet-education-consultancy-services/"
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin"
            >
              <LinkedInIcon />
            </SocialMediaIcon>
          </SocialMedia>
        </CompanyContainer>
        <SiteMap>
          <SiteMapSection>
            <SiteMapTitle>Study Abroad</SiteMapTitle>
            <SiteMapLink to="/study-in-uk">Study in UK</SiteMapLink>
            <SiteMapLink to="/study-in-us">Study in US</SiteMapLink>
            <SiteMapLink to="/study-in-uk">Study in Canada</SiteMapLink>
            <SiteMapLink to="/study-in-us">Study in Australia</SiteMapLink>
            <SiteMapLink to="/study-in-uk">Study in Singapore</SiteMapLink>
            <SiteMapLink to="/study-in-us">Study in Thailand</SiteMapLink>
          </SiteMapSection>
          <SiteMapSection>
            <SiteMapTitle>Our Services</SiteMapTitle>
            <SiteMapLink to="/study-in-uk">Education Counseling</SiteMapLink>
            <SiteMapLink to="/study-in-us">
              Universities Application
            </SiteMapLink>
            <SiteMapLink to="/study-in-us">Visa Application</SiteMapLink>
            <SiteMapLink to="/study-in-us">Scholarship Application</SiteMapLink>
            <SiteMapLink to="/study-in-us">
              Predeparture and Departure Guidance
            </SiteMapLink>
            <SiteMapLink to="/study-in-us">Payment</SiteMapLink>
          </SiteMapSection>
          <LastSiteMapSection>
            <SiteMapTitle>OurCompany</SiteMapTitle>
            <SiteMapLink to="/about">About Us</SiteMapLink>
            <SiteMapLink to="/contact">News & Blogs</SiteMapLink>
            <SiteMapLink to="/contact">Testimonials</SiteMapLink>
            <SiteMapLink to="/portals">Portals</SiteMapLink>
            <SiteMapLink to="/contact">Contact Us</SiteMapLink>
            <SiteMapLink to="/careers">Office Tour</SiteMapLink>
          </LastSiteMapSection>
        </SiteMap>
      </CompanyHeadContainer>
      <FooterWrapper>
        <Copyright>© 2023 SafeNet Education. All Rights Reserved.</Copyright>
        <FooterTxtWrapper>
          <FooterTxt m="40">Privacy Policy</FooterTxt>
          <FooterTxt>Terms of Use</FooterTxt>
        </FooterTxtWrapper>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
