export const MarkIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="28" y="27.3899" width="20" height="20" rx="10" fill="#F7CB57" />
    <g clipPath="url(#clip0_2210_32284)">
      <g clipPath="url(#clip1_2210_32284)">
        <g clipPath="url(#clip2_2210_32284)">
          <mask
            id="mask0_2210_32284"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="6"
            y="1"
            width="33"
            height="43"
          >
            <path d="M38.5 1.88989H6.5V43.8899H38.5V1.88989Z" fill="white" />
          </mask>
          <g mask="url(#mask0_2210_32284)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37.499 43.683C37.294 43.683 37.091 43.62 36.918 43.495L22.499 33.107L8.08 43.495C7.775 43.714 7.375 43.743 7.041 43.571C6.708 43.398 6.5 43.053 6.5 42.674V8.09398C6.5 5.15298 8.87 2.76099 11.786 2.76099H33.214C36.128 2.76099 38.499 5.15298 38.499 8.09398V42.674C38.499 43.053 38.29 43.398 37.957 43.571C37.813 43.647 37.656 43.683 37.499 43.683ZM22.499 30.859C22.704 30.859 22.907 31.21 23.08 31.047L36.499 40.714V8.09398C36.499 6.26498 35.026 4.77798 33.214 4.77798H11.786C9.974 4.77798 8.499 6.26498 8.499 8.09398V40.714L21.918 31.047C22.092 31.21 22.295 30.859 22.499 30.859Z"
              fill="#302E2E"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2210_32284">
        <rect
          width="32"
          height="42"
          fill="white"
          transform="translate(6.5 1.88989)"
        />
      </clipPath>
      <clipPath id="clip1_2210_32284">
        <rect
          width="32"
          height="42"
          fill="white"
          transform="translate(6.5 1.88989)"
        />
      </clipPath>
      <clipPath id="clip2_2210_32284">
        <rect
          width="32"
          height="42"
          fill="white"
          transform="translate(6.5 1.88989)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CheckedIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="28" y="27.3899" width="20" height="20" rx="10" fill="#F7CB57" />
    <g clipPath="url(#clip0_2210_32305)">
      <g clipPath="url(#clip1_2210_32305)">
        <g clipPath="url(#clip2_2210_32305)">
          <mask
            id="mask0_2210_32305"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="5"
            y="5"
            width="35"
            height="36"
          >
            <path d="M40 5.38989H5V40.3899H40V5.38989Z" fill="white" />
          </mask>
          <g mask="url(#mask0_2210_32305)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.4472 40.3877C22.4442 40.3877 22.4402 40.3877 22.4362 40.3877C17.7752 40.3847 13.3932 38.5617 10.9802 35.2557C6.80422 31.9477 5.80022 27.5527 5.50022 22.8777C5.20022 18.2027 6.81422 13.8097 10.1132 10.5057C13.4092 7.20567 17.7882 5.48967 22.4472 5.48967C22.4502 5.48967 22.4522 5.48967 22.4562 5.48967C24.9162 5.38967 27.3032 5.89867 29.5492 6.90267C30.5202 7.12667 30.2792 7.71867 30.5402 8.22367C29.8302 8.72767 29.2432 8.95267 28.7372 8.72967C26.7482 7.83967 24.6342 7.38967 22.4552 7.38767C22.4522 7.38767 22.4492 7.38767 22.4472 7.38767C18.3202 7.38767 14.4412 8.99767 11.5222 11.9207C8.60122 14.8477 6.99122 18.7387 6.98922 22.8787C6.98622 27.0187 8.59222 30.9127 11.5102 33.8417C14.4272 36.7707 18.3082 38.3847 22.4382 38.3867C22.4402 38.3867 22.4442 38.3867 22.4472 38.3867C30.9662 38.3867 37.8992 31.8997 37.9052 22.8977V21.3797C37.9052 20.8277 38.3512 20.3787 38.9022 20.3787C39.4532 20.3787 39.8992 20.8277 39.8992 21.3797V22.8977C39.8932 32.5437 32.6502 40.3877 22.4472 40.3877ZM23.1522 26.9207C22.9652 27.1077 22.7122 27.2137 22.4472 27.2137C22.1832 27.2137 21.9292 27.1077 21.7422 26.9217L16.8052 21.9707C16.4152 21.5807 16.4152 20.9477 16.8052 20.5567C17.1942 20.1657 17.8252 20.1657 18.2152 20.5567L22.4472 24.7997L38.1972 8.99067C38.5862 8.59967 39.2172 8.60067 39.6072 8.98967C39.9972 9.37967 39.9972 10.0127 39.6072 10.5297L23.1522 26.9207Z"
              fill="#302E2E"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2210_32305">
        <rect
          width="35"
          height="35"
          fill="white"
          transform="translate(5 5.38989)"
        />
      </clipPath>
      <clipPath id="clip1_2210_32305">
        <rect
          width="35"
          height="35"
          fill="white"
          transform="translate(5 5.38989)"
        />
      </clipPath>
      <clipPath id="clip2_2210_32305">
        <rect
          width="35"
          height="35"
          fill="white"
          transform="translate(5 5.38989)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const NotiIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="28" y="27.3896" width="20" height="20" rx="10" fill="#F7CB57" />
    <g clipPath="url(#clip0_2210_32326)">
      <g clipPath="url(#clip1_2210_32326)">
        <g clipPath="url(#clip2_2210_32326)">
          <mask
            id="mask0_2210_32326"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="5"
            y="2"
            width="35"
            height="41"
          >
            <path d="M40 2.88965H5V42.8896H40V2.88965Z" fill="white" />
          </mask>
          <g mask="url(#mask0_2210_32326)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M39.858 32.8544C39.728 33.2764 39.347 33.5654 38.911 33.5654H6.58996C5.61996 33.5654 5.23296 33.2744 5.10596 32.8494C5.19996 32.4234 5.14196 32.6394 5.50696 31.7174C5.54996 31.6874 10.539 28.0524 10.539 16.1794C10.539 9.24636 15.898 3.81836 22.485 3.81836C29.72 3.81836 34.431 9.24636 34.431 16.1794C34.431 28.0524 39.42 31.6874 39.47 31.7224C39.828 32.7194 39.988 32.4334 39.858 32.8544ZM32.44 16.1794C32.44 10.3574 27.975 5.83435 22.485 5.83435C16.996 5.83435 12.53 10.3574 12.53 16.1794C12.53 24.5384 10.155 29.2504 8.41196 31.5484H36.558C34.815 29.2504 32.44 24.5384 32.44 16.1794ZM18.828 39.0784C19.305 38.8004 19.913 39.6294 20.189 39.4454C20.42 39.8494 20.754 40.1874 21.153 40.4214C21.766 40.7834 22.482 40.8794 23.167 40.6944C23.852 40.5104 24.426 40.0674 24.781 39.4454C25.57 39.6294 25.667 38.8014 26.142 39.0794C26.617 39.3584 26.779 40.7494 26.503 40.4584C25.643 42.5994 24.8 42.8014 22.477 42.8014C21.686 42.8014 20.886 42.5964 20.154 42.1664C19.455 41.7554 18.871 41.1654 18.466 40.4584C18.19 40.7494 18.352 39.3584 18.828 39.0784Z"
              fill="#302E2E"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2210_32326">
        <rect
          width="35"
          height="40"
          fill="white"
          transform="translate(5 2.88965)"
        />
      </clipPath>
      <clipPath id="clip1_2210_32326">
        <rect
          width="35"
          height="40"
          fill="white"
          transform="translate(5 2.88965)"
        />
      </clipPath>
      <clipPath id="clip2_2210_32326">
        <rect
          width="35"
          height="40"
          fill="white"
          transform="translate(5 2.88965)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CloudIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="28" y="27.79" width="20" height="20" rx="10" fill="#F7CB57" />
    <g clipPath="url(#clip0_2210_32430)">
      <g clipPath="url(#clip1_2210_32430)">
        <g clipPath="url(#clip2_2210_32430)">
          <mask
            id="mask0_2210_32430"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="1"
            y="7"
            width="43"
            height="33"
          >
            <path d="M44 7.29004H1V39.29H44V7.29004Z" fill="white" />
          </mask>
          <g mask="url(#mask0_2210_32430)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.2061 39.1389C10.4501 39.1389 3.79511 34.7899 2.50011 27.9199C1.43011 23.2889 1.55211 19.0599 3.68111 15.4439C5.81211 11.8279 9.22111 9.25995 13.2811 8.21095C17.3421 7.41695 21.5681 7.75795 25.1811 9.88895C28.5661 11.8859 31.3301 15.0089 32.1931 18.7389H33.7441C39.3641 18.7389 43.9361 23.5299 43.9361 28.9389C43.9361 34.5629 39.3641 39.1389 33.7441 39.1389H17.2171C17.2141 39.1389 17.2091 39.1389 17.2061 39.1389ZM17.2301 9.72795C16.8201 9.72795 14.9271 9.87295 13.7861 10.1689C10.2481 11.0829 7.27811 13.6099 5.42211 16.4719C3.56611 19.6219 3.47011 23.4599 3.96111 26.8479C5.52111 32.8949 10.9671 37.1159 17.2071 37.1159C17.2101 37.1159 17.2131 37.1159 17.2171 37.1159H33.7441C38.2491 37.1159 41.9151 33.4479 41.9151 28.9389C41.9151 24.4289 38.2491 20.7599 33.7441 20.7599H31.4301C30.9691 20.7599 30.5671 20.4479 30.4521 20.0019C29.5381 16.4619 27.3021 13.4879 24.1541 11.6309C22.2101 11.1199 19.6431 9.72795 17.2301 9.72795Z"
              fill="#302E2E"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2210_32430">
        <rect
          width="43"
          height="32"
          fill="white"
          transform="translate(1 7.29004)"
        />
      </clipPath>
      <clipPath id="clip1_2210_32430">
        <rect
          width="43"
          height="32"
          fill="white"
          transform="translate(1 7.29004)"
        />
      </clipPath>
      <clipPath id="clip2_2210_32430">
        <rect
          width="43"
          height="32"
          fill="white"
          transform="translate(1 7.29004)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const FaceBookIcon = () => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.442871" width="40" height="40" rx="20" fill="#F1F1F1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6352 29.1699V21.2087H24.3585L24.7671 18.1051H21.6352V16.1239C21.6352 15.2256 21.8885 14.6135 23.2032 14.6135L24.8774 14.6128V11.8368C24.5878 11.7999 23.594 11.7153 22.4373 11.7153C20.0219 11.7153 18.3683 13.1614 18.3683 15.8165V18.1051H15.6367V21.2087H18.3683V29.1699H21.6352Z"
      fill="#AF3C2C"
    />
  </svg>
);

export const InstagramIcon = () => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.442871" width="40" height="40" rx="20" fill="#F1F1F1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6005 10.6245H24.399C27.3871 10.6245 29.8181 13.0555 29.818 16.0434V24.842C29.818 27.8299 27.3871 30.2609 24.399 30.2609H15.6005C12.6125 30.2609 10.1816 27.83 10.1816 24.842V16.0434C10.1816 13.0555 12.6125 10.6245 15.6005 10.6245ZM24.3991 28.5187C26.4265 28.5187 28.0759 26.8694 28.0759 24.842L28.0758 16.0434C28.0758 14.0162 26.4263 12.3667 24.399 12.3667H15.6005C13.5732 12.3667 11.9239 14.0162 11.9239 16.0434V24.842C11.9239 26.8694 13.5732 28.5188 15.6005 28.5187H24.3991ZM14.857 20.4428C14.857 17.6069 17.164 15.2998 19.9998 15.2998C22.8356 15.2998 25.1427 17.6069 25.1427 20.4428C25.1427 23.2786 22.8356 25.5856 19.9998 25.5856C17.164 25.5856 14.857 23.2786 14.857 20.4428ZM16.6278 20.4427C16.6278 22.302 18.1406 23.8146 19.9998 23.8146C21.8591 23.8146 23.3718 22.302 23.3718 20.4427C23.3718 18.5833 21.8592 17.0706 19.9998 17.0706C18.1404 17.0706 16.6278 18.5833 16.6278 20.4427Z"
      fill="#AF3C2C"
    />
  </svg>
);

export const LinkedInIcon = () => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.442871" width="40" height="40" rx="20" fill="#F1F1F1" />
    <path
      d="M27.2 11.4429H12.8C11.81 11.4429 11 12.2529 11 13.2429V27.6429C11 28.6329 11.81 29.4429 12.8 29.4429H27.2C28.19 29.4429 29 28.6329 29 27.6429V13.2429C29 12.2529 28.19 11.4429 27.2 11.4429ZM16.4 26.7429H13.7V18.6429H16.4V26.7429ZM15.05 17.1129C14.15 17.1129 13.43 16.3929 13.43 15.4929C13.43 14.5929 14.15 13.8729 15.05 13.8729C15.95 13.8729 16.67 14.5929 16.67 15.4929C16.67 16.3929 15.95 17.1129 15.05 17.1129ZM26.3 26.7429H23.6V21.9729C23.6 21.2529 22.97 20.6229 22.25 20.6229C21.53 20.6229 20.9 21.2529 20.9 21.9729V26.7429H18.2V18.6429H20.9V19.7229C21.35 19.0029 22.34 18.4629 23.15 18.4629C24.86 18.4629 26.3 19.9029 26.3 21.6129V26.7429Z"
      fill="#AF3C2C"
    />
  </svg>
);

export const LocationIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 10.4429C21 17.4429 12 23.4429 12 23.4429C12 23.4429 3 17.4429 3 10.4429C3 8.05592 3.94821 5.76674 5.63604 4.07891C7.32387 2.39108 9.61305 1.44287 12 1.44287C14.3869 1.44287 16.6761 2.39108 18.364 4.07891C20.0518 5.76674 21 8.05592 21 10.4429Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13.4429C13.6569 13.4429 15 12.0997 15 10.4429C15 8.78602 13.6569 7.44287 12 7.44287C10.3431 7.44287 9 8.78602 9 10.4429C9 12.0997 10.3431 13.4429 12 13.4429Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EmailIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4.44287H20C21.1 4.44287 22 5.34287 22 6.44287V18.4429C22 19.5429 21.1 20.4429 20 20.4429H4C2.9 20.4429 2 19.5429 2 18.4429V6.44287C2 5.34287 2.9 4.44287 4 4.44287Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 6.44287L12 13.4429L2 6.44287"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PhoneIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2310_10851)">
      <path
        d="M15.0501 5.44287C16.0268 5.63344 16.9244 6.11113 17.6281 6.81481C18.3318 7.51848 18.8095 8.41614 19.0001 9.39287L15.0501 5.44287ZM15.0501 1.44287C17.0793 1.66831 18.9716 2.57705 20.4163 4.01988C21.8609 5.46271 22.7721 7.35388 23.0001 9.38287L15.0501 1.44287ZM22.0001 17.3629V20.3629C22.0012 20.6414 21.9441 20.917 21.8326 21.1722C21.721 21.4274 21.5574 21.6565 21.3521 21.8447C21.1469 22.033 20.9046 22.1764 20.6408 22.2656C20.377 22.3548 20.0974 22.3879 19.8201 22.3629C16.7429 22.0285 13.7871 20.977 11.1901 19.2929C8.77388 17.7575 6.72539 15.709 5.19006 13.2929C3.50003 10.6841 2.4483 7.71387 2.12006 4.62287C2.09507 4.34634 2.12793 4.06763 2.21656 3.8045C2.30518 3.54136 2.44763 3.29956 2.63482 3.09449C2.82202 2.88942 3.04986 2.72558 3.30385 2.61339C3.55783 2.50121 3.8324 2.44313 4.11006 2.44287H7.11006C7.59536 2.43809 8.06585 2.60995 8.43382 2.9264C8.80179 3.24286 9.04213 3.68232 9.11005 4.16287C9.23668 5.12294 9.47151 6.0656 9.81006 6.97287C9.9446 7.3308 9.97372 7.71979 9.89396 8.09375C9.81421 8.46772 9.62892 8.81098 9.36005 9.08287L8.09006 10.3529C9.51361 12.8564 11.5865 14.9293 14.0901 16.3529L15.3601 15.0829C15.6319 14.814 15.9752 14.6287 16.3492 14.549C16.7231 14.4692 17.1121 14.4983 17.4701 14.6329C18.3773 14.9714 19.32 15.2062 20.2801 15.3329C20.7658 15.4014 21.2095 15.6461 21.5266 16.0204C21.8437 16.3947 22.0122 16.8724 22.0001 17.3629Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2310_10851">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.442871)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const RedCircleIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3.33374"
      y="3.49976"
      width="29"
      height="29"
      rx="14.5"
      fill="white"
      stroke="#AF3C2C"
      strokeWidth="6"
    />
  </svg>
);

export const WorkIcon = () => (
<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.5" width="32" height="32" rx="16" fill="#738C99"/>
<g clipPath="url(#clip0_2207_10591)">
<path d="M24 10.5H20V8.5C20 7.39 19.11 6.5 18 6.5H14C12.89 6.5 12 7.39 12 8.5V10.5H8C6.89 10.5 6.01 11.39 6.01 12.5L6 23.5C6 24.61 6.89 25.5 8 25.5H24C25.11 25.5 26 24.61 26 23.5V12.5C26 11.39 25.11 10.5 24 10.5ZM18 10.5H14V8.5H18V10.5Z" fill="#F7CB57"/>
</g>
<defs>
<clipPath id="clip0_2207_10591">
<rect width="24" height="24" fill="white" transform="translate(4 4.5)"/>
</clipPath>
</defs>
</svg>
);