import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

// Create styled components for each HTML element
const BreadcrumbNav = styled.div`
  background: transparent;
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  @media screen and (max-width: 1180px) {
    padding: 0.75rem 3rem;
  }
  @media screen and (max-width: 768px) {
    padding: 0.75rem 1.3rem;
    line-height: 2;
  }
`;

const NonNavigableBreadcrumb = styled.span`
  color: #121820;
  font-size: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  color: #121820;
  font-size: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const BreadcrumbGrayLink = styled(Link)`
  text-decoration: none;
  color: #12182080;
  font-size: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Separator = styled.p`
  display: inline-block;
  margin: 0.1rem 1rem;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: #1e56a0;
  font-size: 1rem;

  @media screen and (max-width: 768px) {
    margin: 0.1rem 0.25;
    width: 1.8;
    height: 1.8;
    border-radius: 1.8;
  }
`;

const Breadcrumbs = ({ displayNames = {}, nonNavigablePaths = [] }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <BreadcrumbNav>
      <BreadcrumbLink to="/">Home</BreadcrumbLink>
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const displayName = displayNames[value] || decodeURIComponent(value);

        return nonNavigablePaths.includes(value) ? (
          <span key={to}>
            <Separator></Separator>
            <NonNavigableBreadcrumb>{displayName}</NonNavigableBreadcrumb>
          </span>
        ) : (
          <span key={to}>
            <Separator></Separator>
            <BreadcrumbGrayLink to={to}>{displayName}</BreadcrumbGrayLink>
          </span>
        );
      })}
    </BreadcrumbNav>
  );
};

export default Breadcrumbs;
