import React, { useState, useEffect, lazy, Suspense } from "react";
import Header from "./components/Headers";
import Footer from "./components/Footers";
import { Routes, Route } from "react-router-dom";
import GlobalStyle from "./globalStyles";
import Dropdown from "./components/Dropdown";
import { useLoading } from "./components/LoadingContext";
import CustomLoader from "./components/CustomLoader";
import styled from "styled-components";
import OfficeTour from "./pages/OfficeTour";
import Studyinuk from "./pages/Studyinuk";
import UKuniversitypage from "./pages/UKuniversitypage";
import Studyinus from "./pages/Studyinus";
import USuniversitypage from "./pages/USuniversitypage";
import Studyincanada from "./pages/Studyincanada";
import NewsBlogs from "./pages/NewsBlogs";
import BlogPostDetails from "./pages/BlogPostDetails";
import Canadauniversitypage from "./pages/Canadauniversitypage";
import Studyinaustralia from "./pages/Studyinaustralia";
import Australiauniversitypage from "./pages/Australiauniversitypage";
import Studyinsg from "./pages/Studyinsg";
import Sguniversitypage from "./pages/Sguniversitypage";
import Studyinthai from "./pages/Studyinthai";
import Thaiuniversitypage from "./pages/Thaiuniversitypage";
import Testimonials from "./pages/Testimonials";
import NewImageUploadForm from "./pages/NewImageUploadForm";
import { Helmet } from "react-helmet";
import Payment from "./pages/Payment";
import Ap from "./pages/Ap";
import Vg from "./pages/Vg";
import Sg from "./pages/Sg";
import Pd from "./pages/Pd";
import Ec from "./pages/Ec";
import CookieBanner from "./pages/CookieBanner";
import ScrollToTop from "./components/ScrollToTop";
import NotFoundPage from "./pages/NotFoundPage";

const HomePage = lazy(() => import("./pages/HomePage"));
const AboutUsPage = lazy(() => import("./pages/AboutUsPage"));
const ServicesPage = lazy(() => import("./pages/ServicesPage"));
const ContactInformationPage = lazy(() =>
  import("./pages/ContactInformationPage")
);

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const { isLoading, setIsLoading } = useLoading();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ScrollToTop />
      <CookieBanner />
      <Helmet>
        <meta charSet="utf-8" />
        <title>SafeNet Education</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta name="description" content="SafeNet Education" />
      </Helmet>
      <GlobalStyle />
      {isLoading ? (
        <LoaderContainer>
          <CustomLoader />
        </LoaderContainer>
      ) : (
        <>
          <Header toggle={toggle} />
          <Dropdown isOpen={isOpen} toggle={toggle} />
          <Suspense fallback={<CustomLoader />}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/Company/About Us" element={<AboutUsPage />} />
              <Route path="/Services" element={<ServicesPage />} />
              <Route path="/Services/Education Counseling" element={<Ec />} />
              <Route path="/Services/Application Process" element={<Ap />} />
              <Route path="/Services/Visa Application" element={<Vg />} />
              <Route
                path="/Services/Scholarship Application"
                element={<Sg />}
              />
              <Route path="/Services/Payment" element={<Payment />} />
              <Route
                path="/services/Predeparture and Departure Guidance"
                element={<Pd />}
              />
              <Route
                path="/Company/Contact Information"
                element={<ContactInformationPage />}
              />
              <Route path="/Company/Office Tour" element={<OfficeTour />} />
              <Route path="/study-abroad/uk" element={<Studyinuk />} />
              <Route path="/study-abroad/usa" element={<Studyinus />} />
              <Route path="/study-abroad/canada" element={<Studyincanada />} />
              <Route
                path="/study-abroad/australia"
                element={<Studyinaustralia />}
              />
              <Route path="/study-abroad/singapore" element={<Studyinsg />} />
              <Route path="/study-abroad/thailand" element={<Studyinthai />} />
              <Route
                path="/company/new-testimonial"
                element={<NewImageUploadForm />}
              />
              <Route
                path="/study-abroad/uk/:id"
                element={<UKuniversitypage />}
              />
              <Route
                path="/study-abroad/usa/:id"
                element={<USuniversitypage />}
              />
              <Route
                path="/study-abroad/canada/:id"
                element={<Canadauniversitypage />}
              />
              <Route
                path="/study-abroad/australia/:id"
                element={<Australiauniversitypage />}
              />
              <Route
                path="/study-abroad/singapore/:id"
                element={<Sguniversitypage />}
              />
              <Route
                path="/study-abroad/thailand/:id"
                element={<Thaiuniversitypage />}
              />
              <Route path="/Company/Testimonials" element={<Testimonials />} />
              <Route path="/Company/News and Blog" element={<NewsBlogs />} />
              <Route path="/blog/:id" element={<BlogPostDetails />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
