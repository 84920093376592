import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { menuData } from "../data/MenuData";
import { Button } from "./Button";
import logo from "../images/logo.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { IoPersonOutline } from "react-icons/io5";
const Nav = styled.nav`
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 4rem;
  margin-top: 2rem;
  z-index: 100;
  position: fixed;
  top: 24px;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 20px -20px;

  @media screen and (max-width: 768px) {
    margin-top: 3rem;
    padding: 0rem 1rem;
  }
`;

const MessageDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #738c99;
  z-index: 10000;
  padding: 5px 10px;
  pointer-events: none;
`;

const MessageContentContainer = styled.div`
  padding: 0.8rem 4rem;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    padding: 12px 16px;
    gap: 0.5rem;
  }
`;

const MessageText = styled.p`
  font-family: "Sora";
  font-size: 1rem;
  color: #fff;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: ${(props) => (props.size === "12" ? "12px" : "14px")};
  }
`;

const NavLink = styled(Link)`
  color: #170f49;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  font-weight: 600;

  &::after {
    content: ${({ selected }) => (selected ? "''" : "none")};
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 2px;
  }

  &:hover {
    &::after {
      content: "";
    }
  }
`;

const StyledIcon = styled(IoPersonOutline)`
  font-size: 24px; /* Adjust the size as needed */
  margin-right: 8px; /* Adds margin to the right of the icon */
  font-weight: 600;
`;

const Logo = styled(Link)`
  ${NavLink}
  font-style: italic;
  display: flex; // Add this line
  align-items: center; // Add this line
  text-decoration: none;
  color: #170f49;
  font-weight: 600;
`;
const LogoImage = styled.img`
  height: 40px;
  margin-right: 10px; // Adjust the margin size as needed
`;

const MenuBars = styled.div`
  display: none;
  color: #1466d9;
  margin-right: 4rem;

  @media screen and (max-width: 1180px) {
    display: block;
    cursor: pointer;
    position: absolute;
    right: calc(1.5vw + 5px); // Use calc() and vw unit here
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (max-width: 768px) {
    margin-right: 1rem;
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -48px;

  @media screen and (max-width: 1180px) {
    display: none;
  }
`;

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 1180px) {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const LoginButton = styled(Button)`
  background-color: #f3f3f3;
  color: #ff9624;
  border-radius: 5px;
  font-weight: 600;
`;

const DropdownMenu = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  max-width: 300px;
  width: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  top: 100%;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease;
  margin-top: 20.5px;

  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  a:hover {
    background-color: #f1f1f1;
  }
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${DropdownMenu} {
    display: block;
    opacity: 1;
    pointer-events: auto;
  }

  &::before {
    // Add this block
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 20px; // Adjust this value to be the same as the margin-top value in DropdownMenu
    z-index: -1;
  }
`;
const Header = ({ toggle, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpened, setMenuOpened] = useState(false);

  const handleNavigation = (path, isSublink = false) => {
    if (!isSublink) {
      toggle();
    }
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 3000);
  };

  return (
    <Nav>
      <MessageDiv>
        <MessageContentContainer>
          <MessageText>Let us be part of your journey to success </MessageText>
          <MessageText size="12">safeneteducation@gmail.com</MessageText>
        </MessageContentContainer>
      </MessageDiv>
      <Logo to="/">
        <LogoImage src={logo} alt="SafeNet" />
        SafeNet
      </Logo>
      <MenuBars
        onClick={() => {
          setMenuOpened(!menuOpened);
          toggle();
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            setMenuOpened(!menuOpened);
            toggle();
          }
        }}
        role="button"
        tabIndex="0"
      >
        {menuOpened ? <FaTimes /> : <FaBars />}
      </MenuBars>
      <NavMenu>
        {menuData.map((item, index) => (
          <Dropdown key={index}>
            <NavLink
              to={item.Link}
              onClick={() => handleNavigation(item.Link)}
              selected={
                location.pathname === item.Link ||
                (item.dropdown &&
                  item.dropdown.some(
                    (subItem) => subItem.Link === location.pathname
                  ))
              }
            >
              {item.title}
            </NavLink>
            {item.dropdown && (
              <DropdownMenu>
                {item.dropdown.map((subItem, subIndex) => (
                  <Link
                    to={subItem.Link}
                    key={subIndex}
                    onClick={() => handleNavigation(subItem.Link, true)}
                  >
                    {subItem.title}
                  </Link>
                ))}
              </DropdownMenu>
            )}
          </Dropdown>
        ))}
      </NavMenu>
      <NavBtn>
        <LoginButton to="https://app.safeneteducation.com" primary="true">
          <StyledIcon />
          Login
        </LoginButton>
      </NavBtn>
    </Nav>
  );
};

export default Header;
