import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  overflow-x: auto; 
  min-height: 100vh;
  font-family: 'Sora', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Syne', sans-serif;
}

p, span, a, button {
  font-family: 'Sora', sans-serif;
}
`;

export default GlobalStyle;
